import { render, staticRenderFns } from "./Huay.vue?vue&type=template&id=a52d4402&scoped=true&"
import script from "./Huay.vue?vue&type=script&lang=js&"
export * from "./Huay.vue?vue&type=script&lang=js&"
import style0 from "./Huay.vue?vue&type=style&index=0&id=a52d4402&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a52d4402",
  null
  
)

export default component.exports