<template>
  <div>
    <div class="center" v-if="showLoading">
		  <lottie :options="defaultOptionsSplashScreen" :height="300" :width="300" v-on:animCreated="handleAnimationSplashScreen"/>
	  </div>
    <div v-else>
      <headerSection></headerSection>
      <div id="main__content" class="">
        <div class="x-main-container">
          <div class="x-main-content">
            <div class="x-promotion-index" :style="{'background-image': `url(${require('../assets/images/Shiba-BG.jpg')})`}">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="x-page-title-component -long-support">
                                <div class="-inner-wrapper">
                                <h1 class="-title" style="font-size:20px;color:#e2d702;">วงล้อรับเครดิตฟรี</h1>
                                </div>
                            </div>
                            <p class="-description" style="color:#fff">**เงื่อนไขฝากครบ 500 บาทต่อวัน ได้รับสิทธิ์ทันที**</p>
                        </div>
                    </div>
                </div>
            <!-- 'height':'100vh'-->
              <div class="container">
                <div class="x-wrapper-right-container"></div>
                  <div id="contact_gl" class="section" style="padding-top: -100px !important;">
                      <div class="spin" style="display: flex; align-items: center; justify-content: center;">
                          <canvas id="canvas" width="455" height="455"></canvas>
                      </div>
                      <p class="glow">{{loadingtext}}</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <footerSection></footerSection>
      </div>
    </div>
  </div>
</template>
<script>
import firebase, { database } from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import _ from 'lodash'
import router from './../router'
import Lottie from './lottie.vue'
import * as animateSantaWalk from '../assets/loading.json'
import moment from 'moment-timezone'
const axios = require('axios').default
require('vue2-animate/dist/vue2-animate.min.css')
// @ is an alias to /src
export default {
  name: 'Gift',
  components: {
    headerSection: () => import('./Header.vue'),
    footerSection: () => import('./Footer.vue'),
    lottie: Lottie
  },
  created () {
    const user = localStorage.getItem('userData')
	  if (user) {
		  const _user = JSON.parse(user)
		  this.$store.dispatch('user/setUser', _user).then(()=>{
        this.checkUserId()
      }).catch(err => { console.error(err) })
	  } else {
      firebase.auth().signOut()
      localStorage.removeItem('userData')
      router.replace('/login')
	  }
    const animateSplashScreen = animateSantaWalk.default
    this.defaultOptionsSplashScreen = { animationData: animateSplashScreen, autoplay: false, loop: false }
  },
  computed: {
    user () {
      return this.$store.state.user.user
    }
  },
  mounted () {
      this.animSplashScreen.play()
      setTimeout(() => {
        this.showLoading = false
        this.animSplashScreen.stop()
        setTimeout(() => {
          this.canvas = document.getElementById('canvas')
          this.repaint(this.angle)
        }, 500)
      }, 3500)
      this.getGames()
  },
  data () {
    return {
      logoLeft: '',
      logoRight: '',
      angle: 0,
      gameKey: 'spin',
      showLoading: true,
      pictureUrl: '',
      code: '',
      games_web: [],
      search: '',
      loading: false,
      error: false,
      web: [],
      message: 'Invalid user name or password!',
      point: 0,
      favorites: [],
      animationInfinite: {
        classes: 'fadeIn',
        duration: 5000,
        iteration: 'infinite'
      },
      permission: [],
      defaultOptions: null,
      defaultOptions2: null,
      defaultOptionsSplashScreen: null,
      openGiftData: false,
      canOpen: false,
      percentage: 0,
      loadingtext: '',
      credit: '',
      save: false,
      canvas: null,
      frame: null,
      wheels: null,
      sections: [],
      sectionspoint: [],
      colors: [],
      percent: [],
    }
  },
  methods: {
    handleAnimationSplashScreen: function (anim) {
      this.animSplashScreen = anim
    },
    async getGames () {
      this.games_web = (await firebase.database().ref('/spin').once('value')).val()
    },
    async checkUserId () {
      await firebase.firestore().collection('spin').doc(this.user.key + '-' + moment().tz('Asia/Bangkok').format('YYYY-MM-DD')).get().then(async (snapshot) => {
        if (snapshot.exists) {
          this.$confirm(
            {
              message: '🎡 ลูกค้าหมุนวงล้อของเราไปแล้วนะคะวันนี้ รอวันพรุ่งนี้นะคะ 😘',
              button: {
                yes: 'OK'
              },
              callback: confirm => {
                if (confirm) {
                  this.$router.push('/')
                }
              }
            }
          )
        } else {
          await firebase.firestore().collection('history').where('userkey','==',this.user.key).where('type','==','ฝาก').where('status','==','ดำเนินการแล้ว').where('createdate','>=',moment().tz("Asia/Bangkok").format("YYYY-MM-DD")+' 00:00:00').where('createdate','<=',moment().tz("Asia/Bangkok").format("YYYY-MM-DD")+' 23:59:59').get().then(async (snapshot) => {
            let credit = 0;
            snapshot.forEach((doc)=>{
              credit += Number(doc.data().qty);
            });
            if(credit >= 500){
              this.canOpen = true;
            }else{
              const less = 500-Number(credit);
              this.loadingtext = `เติมเงินอีก ${less}(500) ได้เล่นเลยค่ะ!😘😘`;
            }
          });
        }
      })
    },
    checkCssBox (game) {
      if (!game.status) {
        return 'box-shadow: 0 0 12px 5px #cccccc;border: 2px solid #cccccc;'
      }
    },
    checkCss (game) {
      if (!this.permission || !this.permission[game.key]) {
        return 'filter: grayscale(100%);'
      }
      // const index = _.findIndex(this.favorites, function(o) { return o == code; });
      // if (index>-1) {
      //   return '';
      // } else {
      //   return 'filter: grayscale(100%);';
      // }
    },
    goto (type) {
      if (type === 'home') {
        // window.scrollTo({
        //   top: 0,
        //   left: 0,
        //   behavior: 'smooth'
        // });
        this.$router.push('/').catch(() => {})
      } else if (type === 'website') {
        window.location = this.web.link
        // this.gotoline()
      } else if (type === 'contact') {
        window.location = this.web.link_line
        // this.gotoline()
      }
    },
    repaint (angle) {
      if (this.games_web && this.sections.length === 0) {
        let ty = 'เครดิต'
        if (this.games_web.type !== 'credit') {
          ty = 'คะแนน'
        }
        for (let j = 1; j <= 13; j++) {
          if (this.games_web[`spin${j}slot`]) {
            this.sections.push('สูตร ' + this.games_web[`spin${j}`] + ' ชั่วโมง')
            this.sectionspoint.push('สูตร ' + this.games_web[`spin${j}`] + ' ชั่วโมง')
          } else {
            this.sections.push(this.games_web[`spin${j}`] + ' ' + ty)
            this.sectionspoint.push(this.games_web[`spin${j}`])
          }

          this.colors.push(this.games_web[`spin${j}color`])
          if (this.games_web[`spin${j}percent`]) {
            for (let p = 1; p <= this.games_web[`spin${j}percent`]; p++) {
              if (this.games_web[`spin${j}slot`]) {
                this.percent.push('สูตร ' + this.games_web[`spin${j}`] + ' ชั่วโมง')
              } else {
                this.percent.push(this.games_web[`spin${j}`])
              }
            }
          }
        }
      }
      const innerHeight = screen.height - 300
      const r = Math.min(innerWidth, innerHeight) / 2.25 | 0
      if (this.wheels === null) {
        this.wheels = []
        for (let selected = 0; selected < this.sections.length; selected++) {
          const c = document.createElement('canvas')
          c.width = c.height = 2 * r + 10
          const ctx = c.getContext('2d'); const cx = 5 + r; const cy = 5 + r
          const g = ctx.createRadialGradient(cx, cy, 0, cx, cy, r)
          g.addColorStop(0, 'rgba(0,0,0,0)')
          g.addColorStop(1, 'rgba(0,0,0,0.1)')
          for (let i = 0; i < this.sections.length; i++) {
            const a0 = 2 * Math.PI * i / this.sections.length
            const a1 = a0 + 2 * Math.PI / (i == 0 ? 1 : this.sections.length)
            const a = 2 * Math.PI * (i + 0.5) / this.sections.length
            ctx.beginPath()
            ctx.moveTo(cx, cy)
            ctx.arc(cx, cy, r, a0, a1, false)
            ctx.fillStyle = this.colors[i]
            ctx.fill()
            ctx.fillStyle = g
            ctx.fill()
            ctx.save()
            if (i == selected) {
              ctx.fillStyle = '#FFF'
              ctx.shadowColor = '#FFF'
              ctx.shadowBlur = r / 20
            } else {
              ctx.fillStyle = '#000'
            }
            const check = this.sections[i].split(' ')
            if (check[0] === 'สูตร') {
              ctx.font = 'bold ' + r / this.sections.length * 1.0 + 'px serif'
            } else {
              ctx.font = 'bold ' + r / this.sections.length * 1.2 + 'px serif'
            }
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            ctx.translate(cx, cy)
            ctx.rotate(a)
            ctx.fillText(this.sections[i], r * 0.62, 0)
            ctx.restore()
          }
          this.wheels.push(c)
        }
      }
      if (this.frame === null) {
        this.frame = document.createElement('canvas')
        this.frame.width = this.frame.height = 10 + 2 * r * 1.25 | 0
        const ctx = this.frame.getContext('2d'); const cx = this.frame.width / 2; const cy = this.frame.height / 2
        ctx.shadowOffsetX = r / 80
        ctx.shadowOffsetY = r / 80
        ctx.shadowBlur = r / 40
        ctx.shadowColor = 'rgba(0,0,0,0.5)'
        ctx.beginPath()
        ctx.arc(cx, cy, r * 1.028, 0, 2 * Math.PI, true)
        ctx.arc(cx, cy, r * 0.975, 0, 2 * Math.PI, false)
        ctx.fillStyle = '#444'
        ctx.fill()
        ctx.shadowOffsetX = r / 40
        ctx.shadowOffsetY = r / 40
        const g = ctx.createRadialGradient(cx - r / 7, cy - r / 7, 0, cx, cy, r / 3)
        g.addColorStop(0, '#FFF')
        g.addColorStop(0.2, '#F44')
        g.addColorStop(1, '#811')
        ctx.fillStyle = g
        ctx.beginPath()
        ctx.arc(cx, cy, r / 3.5, 0, 2 * Math.PI, false)
        ctx.fill()
        ctx.translate(cx, cy)
        ctx.rotate(Math.PI - 0.18)
        ctx.beginPath()
        ctx.moveTo(-r * 1.1, -r * 0.07)
        ctx.lineTo(-r * 0.85, 0)
        ctx.lineTo(-r * 1.1, r * 0.07)
        ctx.fillStyle = '#F44'
        ctx.fill()
      }

      this.canvas.width = innerWidth
      this.canvas.height = innerHeight
      const cx = innerWidth / 2; const cy = innerHeight / 2
      const ctx = this.canvas.getContext('2d')
      let selected = (Math.floor((-0.2 - angle) * this.sections.length / (2 * Math.PI)) %
                        this.sections.length)
      if (selected < 0) selected += this.sections.length
      ctx.save()
      ctx.translate(cx, cy)
      ctx.rotate(angle)
      ctx.translate(-this.wheels[selected].width / 2, -this.wheels[selected].height / 2)
      ctx.drawImage(this.wheels[selected], 0, 0)
      ctx.restore()
      ctx.drawImage(this.frame, cx - this.frame.width / 2, cy - this.frame.height / 2)
      if (this.canOpen) {
        this.canvas.onmousedown = () => {
          if (!this.running) {
            // console.log(Math.floor(Math.random() * this.percent.length));
            const r = Math.floor(Math.random() * this.percent.length)
            const item = this.percent[r]
            const rands = []
            for (const x in this.sectionspoint) {
              if (this.sectionspoint[x] === item) {
                // console.log('this.numorder[x]='+this.sectionspoint[x]+'=x='+x+'=item='+item);
                rands.push(x)
              }
            }
            const rand = Math.floor(Math.random() * rands.length)
            // console.log(rand);
            // let isnum = /^\d+$/.test(item);
            // if(this.games_web.type === 'credit'){
            //     firebase.database().ref(`users/${this.$projectId}/${this.user.key}/games`).update({
            //         [`${this.gameKey}`]: firebase.database.ServerValue.increment(-1)
            //     })
            // }else{
            //     const update = {}
            //     if(isnum){
            //       this.point = this.point + Number(item)
            //       update[`point`] = firebase.database.ServerValue.increment(Number(item))
            //     }
            //     update[`games/${this.gameKey}`] = firebase.database.ServerValue.increment(-1)
            //     firebase.database().ref(`users/${this.$projectId}/${this.user.key}`).update(update)
            // }
            // let status = (this.games_web.type === 'credit')?false:true;
            setTimeout(() => {
              axios({
                method: 'post',
                url: 'https://api.pix888.net/qspin/spin',
                data: {
                  key: this.user.key,
                  code: this.user.code,
                  credit: item
                }
              })
            }, 5000)
            // if(!isnum){
            //   //สูตร 2 ชั่วโมง
            //   const n = item.split(' ');
            //   const games = {};
            //   games['hackslot'] = {};
            //   games['hackslot'].value = Number(n[1]);
            //   games['hackslot'].expiredate = Number(moment().tz("Asia/Bangkok").add(n[1],'hours').format("YYYYMMDDHHmmss"));
            //   games['hackslot'].expiredate2 = moment().tz("Asia/Bangkok").add(Number(n[1]),'hours').format("YYYY-MM-DD HH:mm:ss");
            //   firebase.database().ref(`users/${this.$projectId}/`+this.user.key+'/games').update({
            //     hackslot:games['hackslot'],
            //   })
            // }
            // firebase.firestore().collection('transections').add({
            //     code: this.user.code,
            //     mobile: this.user.mobile,
            //     createdate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
            //     game: this.gameKey,
            //     game_name: this.games_web.name,
            //     qty: item,
            //     type: this.games_web.type,
            //     status: (isnum)?status:true,
            //     web: this.$projectId
            // })
            this.spinTo(Number(rands[rand]), 5000)
          }
        }
      }
    },
    spinTo (winner, duration) {
      this.canOpen = false
      const finalAngle = (-0.2) - (0.5 + winner) * 2 * Math.PI / this.sections.length
      const startAngle = this.angle - Math.floor(this.angle / (2 * Math.PI)) * 2 * Math.PI - 5 * 2 * Math.PI
      const start = performance.now()
      const frame = () => {
        const now = performance.now()
        let t = Math.min(1, (now - start) / duration)
        t = 3 * t * t - 2 * t * t * t // ease in out
        this.angle = startAngle + t * (finalAngle - startAngle)
        this.repaint(this.angle)
        if (t < 1) requestAnimationFrame(frame); else {
          const coin = this.sections[winner].split(' ')
          if (Number(coin[0]) > 0) {
            // this.mycoin += Number(coin[0]);
            this.$confirm(
              {
                message: `🎡 ปังปุรีเย่!! คุณได้รับ ${this.sections[winner]} 😘`,
                button: {
                  yes: 'OK'
                },
                callback: confirm => {
                  if (confirm) {
                    // this.getUserId()
                  }
                }
              }
            )
          } else if (coin[0] === 'สูตร') {
            this.$confirm(
              {
                message: `🎡 ปังปุรีเย่!! คุณได้รับ ${this.sections[winner]} 😘`,
                button: {
                  yes: 'OK'
                },
                callback: confirm => {
                  if (confirm) {
                    // this.getUserId()
                  }
                }
              }
            )
          }
        }
      }
      requestAnimationFrame(frame)
      this.running = true
    }

  }
}
</script>
<style scoped>
.glow {
    color: #fff;
    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
    }

    @-webkit-keyframes glow {
    from {
        text-shadow: 0 0 10px #dbc47a, 0 0 20px #dbc47a, 0 0 30px #dbc47a, 0 0 40px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a;
    }
    
    to {
        text-shadow: 0 0 10px #dbc47a, 0 0 20px #dbc47a, 0 0 30px #dbc47a, 0 0 40px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a;
    }
}
.center {
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#contact_gl {
  min-height: 600px;
  color: #333;
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
	/*background: -moz-radial-gradient(center, ellipse cover, #f7f7f7 0%, #d1d1d1 100%);
	background: -webkit-radial-gradient(center, ellipse cover, #f7f7f7 0%,#d1d1d1 100%);
	background: radial-gradient(ellipse at center, #f7f7f7 0%,#d1d1d1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#d1d1d1',GradientType=1 );*/
}
#contact_gl::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}
.boxlogin {
    background: #fff;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    box-shadow: 0 0 40px #000;
    color: #8b8c8d;
    font-size: 0;
}
.boxlogin .box-part {
    display: inline-block;
    position: relative;
    vertical-align: top;
    height: 100%;
}
.boxlogin, .box .box-part {
    box-sizing: border-box;
}
.boxlogin .partition {
    width: 100%;
    height: 100%;
}
.boxlogin .partition .partition-title {
    box-sizing: border-box;
    padding: 30px;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    font-size: 20px;
    font-weight: 300;
}
.boxlogin .partition .partition-form {
    padding: 0 20px;
    box-sizing: border-box;
}
.boxlogin .button-set {
    margin-bottom: 8px;
}
.boxlogin .github-btn {
    border-color: #dba226;
    color: #dba226;
}
.boxlogin .large-btn {
    width: 100%;
    background: #fff;
}
.boxlogin button {
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    letter-spacing: 1px;
    font-family: Open Sans,sans-serif;
    font-weight: 400;
    min-width: 140px;
    margin-top: 8px;
    color: #8b8c8d;
    cursor: pointer;
    border: 1px solid #dddedf;
    text-transform: uppercase;
    transition: all .1s;
    font-size: 10px;
    outline: none;
}
.spin {   padding: 0px;
          margin: 0px;
          overflow: hidden;
          height: 100%;
/*background: rgb(255,204,92,1);
background: radial-gradient(circle, rgba(255,204,92,1) 49%, rgba(34,193,195) 100%);*/
      }
 .p2 {
	 letter-spacing: 0;
	 text-shadow: 0 0 80px rgba(255, 255, 255, .5);
	/* Clip Background Image */
	 background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
	 -webkit-background-clip: text;
	 background-clip: text;
	/* Animate Background Image */
	 -webkit-text-fill-color: transparent;
	 -webkit-animation: aitf 80s linear infinite;
	/* Activate hardware acceleration for smoother animations */
	 -webkit-transform: translate3d(0, 0, 0);
	 -webkit-backface-visibility: hidden;
}
/* Animate Background Image */
 @-webkit-keyframes aitf {
	 0% {
		 background-position: 0% 50%;
	}
	 100% {
		 background-position: 100% 50%;
	}
}
</style>
